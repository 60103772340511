.global-container {
  width: 100vw;
  height: 100vh;
  overflow-x: hidden;
  overflow-y: auto;
}

.global-loading {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  background-color: rgba(155, 155, 224, 0.5);
  z-index: 10;

  img {
    width: 15%;
    height: 15%;
    object-fit:contain;
  }
}