.product-list-container {
  height: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  @include respond-to('tiny') {
    width: 90%;
  }
  @include respond-to('basic') {
    width: 65%;
  }
}

.product-card-container {
  height: 385px;
  background-color: #fff;
  padding: 5px;
  border-radius: 8px;
  box-shadow: 0 0 5px 5px rgba(128, 128, 128, 0.1);

  @include respond-to('tiny') {
    width: calc(50% - 10px);
    margin: 5px;
  }

  @include respond-to('basic') {
    width: 22%;
    margin: 0 20px 20px 0;
  }

  .product-card-img {
    width: 100%;
    height: 250px;
    background-color: transparent;

    img {
      width: 100%;
      height: 100%;
      object-position: center;
      object-fit: cover;
    }
  }

  .product-card-content {
    width: 100%;
    height: 125px;
    .product-card-title {
      height: 50%;
    }
    .product-card-warehouse {
      height: 25%;
      display: flex;
      align-items: flex-end;
    }
    .product-card-price {
      height: 25%;
      font-size: 14px;
      font-weight: 700;
      display: flex;
      align-items: flex-end;
    }
  }
}

.product-modal-wrapper {
  @include respond-to('tiny') {
    width: 85vw;
    height: 70vh;
  }
  @include respond-to('basic') {
    width: 60vw;
    height: 70vh;
  }

  .product-detail-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    @include respond-to('tiny') {
      flex-direction: column;
    }
    @include respond-to('basic') {
      flex-direction: row;
    }
    

    .product-detail-img {
      background-color: #fff;

      @include respond-to('tiny') {
        width: 100%;
        height: 50%;
      }
      @include respond-to('basic') {
        width: 50%;
        height: 100%;
      }
      

      img {
        
        height: 100%;
        object-fit: contain;
        object-position: center;
        border-radius: 10px;
        box-shadow: 0 0 5px 5px rgba(128, 128, 128, 0.1);

        @include respond-to('tiny') {
        width: 100%;
        }
        @include respond-to('basic') {
          width: 90%;
        }

      }
    }
    .product-detail-desc {
      background-color: #fff;
      overflow-y: auto;
      -ms-overflow-style: none;  /* IE and Edge */
      scrollbar-width: none;  /* Firefox */

      @include respond-to('tiny') {
        width: 100%;
        height: 50%;
      }
      @include respond-to('basic') {
        width: 50%;
        height: 100%;
      }
      
    }

    .product-detail-desc::-webkit-scrollbar {
      display: none;
    }
  }
}