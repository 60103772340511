$breakpoints: (
  "iphone5" : (min-width : 320px) and (max-height : 568px),
  "iphone678" : (min-width : 375px) and (min-height : 667px),
  "iphonex" : (min-width : 375px) and (min-height : 812px),
  "pixel2xl" : (min-width : 411px) and (min-height : 823px),
  "newsamsung" : (min-width : 412px) and (min-height : 890px),
  "tiny" : (
    min-width: 320px
  ),
  "med": (
    min-width: 720px
  ),
  "tiny-max" : (
    (max-width: 1365px)
  ),
  "small":  (
    min-width: 480px
  ),
  "medium": (
    min-width: 768px
  ),
  "large": (
    min-width: 992px
  ),
  "ipadpro-p": (min-width : 1024px) and (min-height : 1366px),
  "xlarge": (
    min-width: 1200px
    ),
  "ipadpro-l": (min-width : 1366px) and (min-height : 1024px),
  "basic" : (
    min-width: 1366px
  ),
  "macbook-pro" :(
    min-width: 1440px
  ),
  "huge": (
    min-width: 1920px
  ),
  "ultra": (
    min-width: 2560px
  )
);

@mixin respond-to($breakpoint) {
  $raw-query: map-get($breakpoints, $breakpoint);

  @if $raw-query {
    $query: if(
      type-of($raw-query) == "string",
      unquote($raw-query),
      inspect($raw-query)
    );

    @media #{$query} {
      @content;
    }
  } @else {
    @error 'No value found for `#{$breakpoint}`. '
         + 'Please make sure it is defined in `$breakpoints` map.';
  }
}

@mixin centerV {
  position: absolute;
  top : 50%;
  transform: translateX(-50%);
}