.btn-modal-close {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 1px solid rgba(128, 128, 128, 0.4);
  position: absolute;
  top:1px;
  right: 1px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}