.mb {
  margin-bottom: 1rem;
}

.devider {
  @include respond-to('tiny') {
    width: 100vw;
    height: 30px;
  }

  @include respond-to('basic') {
    width: 100vw;
    height: 60px;
  }
}