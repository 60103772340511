.navbar-container {
  width: 100%;
  height: 10vh;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  

  img {
    height: 100%;

    @include respond-to('tiny') {
      width: 150px;
    }
    @include respond-to('basic') {
      width: 300px;
    }
  }
}

.navbar-menu {
  position: absolute;
  top: 5vh;
  left: 10%;
  transform: translateY(-50%);

  @include respond-to('tiny') {
    display: flex;
  }
  @include respond-to('basic') {
    display: none;
  }

}