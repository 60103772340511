.filter-list-container {
  width: 20%;
  height: auto;

  @include respond-to('tiny') {
    display: none;
  }
  @include respond-to('basic') {
    display: inline-block;
  }
}

.mobile-filter-list-container {
  width: 20%;
  height: auto;

  @include respond-to('tiny') {
    width: 80%;
    padding: 15px;
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgb(47, 163, 47);
  }
  @include respond-to('basic') {
    display: none;
  }

  .category-menu {
    display: flex;
    justify-content: flex-end;
    color: #fff;
  }
}