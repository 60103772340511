.home-container {
  width: 100%;
  height: auto;
  background-color: $bg-theme;
  padding: 20px 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.pagination {
  width: calc(100% - 60px);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  @include respond-to('tiny') {
    width: 100%;
  }
  @include respond-to('basic') {
    width: calc(100% - 60px);
  }
  

  .pagination-total {
    @include respond-to('tiny') {
      display: none;
    }
    @include respond-to('basic') {
      display: flex;
      flex-direction: row;
    }

    .pagination-text {
      display: flex;
      align-items: center;
    }

    .pagination-select {
      margin: 0 5px;
      select {
        padding: 5px 10px;
        border-radius: 5px;
      }
    }
  }

  .pagination-page {
    @include respond-to('tiny') {
      width: 100%;
      font-size: 12px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    @include respond-to('basic') {
      width: auto;
      font-size: 15px;
    }
    .pagination-container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      list-style-type: none;
      
      .pagination-prev {
        padding: 5px;
        border: 1px solid grey;
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
      }
      .pagination-next {
        padding: 5px;
        border: 1px solid grey;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
      }
      .pagination-active {
        background-color: rgba(184, 182, 182, 0.4);
        padding: 5px;
        border: 1px solid grey;
      }
      .pagination-link {
        padding: 5px 10px;
        border: 1px solid grey;
      }
      .pagination-break {
        padding: 5px;
      }
    }
  }
}