@font-face {
  font-family: 'AvenirRegular';
  src: local('Avenir Next LT Pro'), url('../../fonts/Avenir/AvenirNextLTPro-Regular.otf') format('truetype');
}

@font-face {
  font-family: 'AvenirBold';
  src: local('Avenir Next LT Pro'), url('../../fonts/Avenir/AvenirNextLTPro-Bold.otf') format('truetype');
}

@font-face {
  font-family: 'AvenirItalic';
  src: local('Avenir Next LT Pro'), url('../../fonts/Avenir/AvenirNextLTPro-It.otf') format('truetype');
}

@font-face {
  font-family: 'VNINormal';
  src: local('VNI Times'), url('../../fonts/vni-times/VNITimes-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'VNIBold';
  src: local('VNI Times'), url('../../fonts/vni-times/VNITimes-Bold.ttf') format('truetype');
}

body {
  font-family: 'AvenirRegular';
}


a {
  text-decoration: none !important;
  color: black
}

a:hover {
  cursor: pointer;
}

h1, h2, h3, h4, h5 {
  margin: 0;
  padding: 0;
  font-family: 'AvenirBold';
}

p {
  margin: 0;
  padding: 0;
}

.mh10 {
  margin: 10px 0;
}

.mh5 {
  margin: 5px 0;
}

.title {
  font-weight: 700 ;
  @include respond-to('tiny') {
    font-size: 12px;
  }
  @include respond-to('basic') {
    font-size: 15px;
  }
}

.subtitle {
  font-weight: 200;
  color: rgba(128, 128, 128, 1);
}

.subcategory-wrapper {
  padding: 20px;
  .subcategory {
    font-weight: 700;
    font-size: 12px;
    margin-bottom: 5px;
    cursor: pointer;
  }

  @include respond-to('tiny') {
    font-size: 12px;
  }
  @include respond-to('basic') {
    font-size: 15px;
  }
}


.small-grey-text {
  font-weight: 200;
  color: rgba(128, 128, 128, 1);
  font-size: 12px;
}